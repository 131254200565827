var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"right":_vm.x === 'right',"timeout":_vm.timeout,"top":_vm.y === 'top'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarMessage))]),_c('v-row',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"xs":"12","sm":"2","md":"1"}},[_c('v-btn',{attrs:{"dark":"","block":"","color":"rgb(231,87,83)","to":{
          name: 'Dashboard',
        }}},[_vm._v("Back")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{
          'items-per-page-options': [10, 20, 30]
        },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.items.indexOf(item) + 1))])]}},{key:"item.firstName",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","color":"indigo"},on:{"click":function($event){return _vm.getLiveCalls(item)}}},[_vm._v(" LIVE CALL ")])]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogLiveCall),callback:function ($$v) {_vm.dialogLiveCall=$$v},expression:"dialogLiveCall"}},[_c('v-card',[(_vm.totalLiveCalls)?_c('v-card-title',{staticClass:"headline"},[_vm._v(" Total number of live calls:0 ")]):_vm._e(),(!_vm.totalLiveCalls)?_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.totalLiveCalls)+" ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelDialog}},[_vm._v("Cancel")]),_c('v-spacer')],1)],1)],1)]},proxy:true}],null,true)})],1),_vm._v("` ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }